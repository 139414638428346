import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Grid,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import classNames from 'classnames';
import lookup from 'country-code-lookup';
import MaterialTable from 'material-table';
import _ from 'lodash';
import { VaspRegisterType } from '../constants';
import { getRegistrationType } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  capitalizedTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '& input': {
      textTransform: 'capitalize',
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonNormal: {
    width: '12em',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function VaspForm({
  parent,
  vaspDetail,
  onSubmit,
  onChange,
  onDateChange,
  getError = () => { },
  loading = false,
  updateSuccess = false,
}) {
  const classes = useStyles();

  const updateButtonClassname = classNames(classes.buttonNormal, {
    [classes.buttonSuccess]: updateSuccess,
  });

  const isEditableForUpdateVasp = parent === 'update-vasp';
  const country = vaspDetail.country_code ? lookup.byIso(vaspDetail.country_code) : undefined;

  const renderNotificationReceivers = () => {
    if (vaspDetail.registration_type !== VaspRegisterType.EmailProtocol) {
      return null;
    }
    return (
      <div>
        <TextField
          id="notification_receivers"
          label="Travel Rule Data Exchange Email Address"
          style={{ margin: 8 }}
          fullWidth
          margin="normal"
          name="notification_receivers"
          multiline
          value={(vaspDetail.notification_receivers || []).join(',')}
          disabled
        />
      </div>
    );
  };

  const renderAssets = () => {
    if (vaspDetail.registration_type !== VaspRegisterType.EmailProtocol) {
      return null;
    }

    if (!vaspDetail?.assets?.length || vaspDetail?.assets?.length === 0) {
      return null;
    }

    const rawAssets = vaspDetail.assets.map((asset) => {
      const { currency_id: currencyID, address, addrExtraInfo = [] } = asset;
      const extraInfo = addrExtraInfo
        .map((extra) => Object.entries(extra || {}).map(([k, v]) => `${k}=${v}`))
        .join(',');
      return {
        currency_id: currencyID,
        address,
        addr_extra_info: extraInfo,
      };
    });

    return (
      <div style={{ margin: 8 }}>
        <MaterialTable
          title="Wallet Addresses"
          columns={[
            { title: 'Currency ID', field: 'currency_id', cellStyle: { padding: '1em' } },
            { title: 'Address', field: 'address' },
            { title: 'Address Extra Info', field: 'addr_extra_info' },
          ]}
          data={rawAssets}
          options={{
            headerStyle: { padding: '1em' },
          }}
        />
      </div>
    );
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <div>
        <TextField
          id="registration_type"
          label="Registration Type"
          style={{ margin: 8, width: 400 }}
          fullWidth
          margin="normal"
          name="registration_type"
          value={getRegistrationType(vaspDetail.registration_type || 0)}
          disabled
        />
        <TextField
          id="email"
          label="Email"
          style={{ margin: 8, width: 400 }}
          inputProps={{
            pattern:
              '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/',
          }}
          error={_.get(vaspDetail, 'errors.emailCheck')}
          fullWidth
          margin="normal"
          helperText={_.get(vaspDetail, 'errors.emailCheck')}
          required
          name="vasp_email"
          type="email"
          value={vaspDetail.vasp_email || ''}
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
        <TextField
          id="floating-label-required-error-text-field"
          label="Company Name"
          style={{ margin: 8 }}
          placeholder="Your registered company name"
          fullWidth
          margin="normal"
          helperText={getError('vasp_name')}
          required
          name="vasp_name"
          value={vaspDetail.vasp_name || ''}
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
        {vaspDetail.vasp_code ? <TextField
          label="VASP Code"
          id="floating-label-required-error-text-field"
          className={classes.textField}
          style={{ margin: 8 }}
          helperText={getError('vasp_code')}
          inputProps={{ minLength: 4, maxLength: 4, pattern: '[A-Z]*' }}
          required
          disabled
          name="vasp_code"
          value={vaspDetail.vasp_code || ''}
          onChange={onChange}
        /> :
          <>
            <TextField
              label="VASP Code Name"
              id="floating-label-required-error-text-field"
              className={classes.textField}
              style={{ margin: 8 }}
              helperText={getError('vasp_code_prefix')}
              inputProps={{ minLength: 4, maxLength: 4, pattern: '[A-Z]*' }}
              required
              disabled
              name="vasp_code_prefix"
              value={vaspDetail.vasp_code_prefix || ''}
              onChange={onChange}
            />
            <TextField
              label="Country Code"
              id="floating-label-required-error-text-field"
              className={classes.textField}
              style={{ margin: 8 }}
              required
              disabled
              name="country_code"
              value={country ? `${country.iso2} (${country.country})` : ''}
            />
            <TextField
              label="City Code"
              id="margin-none"
              className={classes.textField}
              style={{ margin: 8 }}
              inputProps={{ minLength: 2, maxLength: 2, pattern: '[A-Z]+' }}
              helperText={getError('city_code')}
              required
              disabled
              name="city_code"
              value={vaspDetail.city_code || ''}
              onChange={onChange}
            />
            <TextField
              label="Branch Code"
              id="margin-none"
              className={classes.textField}
              style={{ margin: 8 }}
              inputProps={{ minLength: 3, maxLength: 3 }}
              helperText={getError('branch_code')}
              name="branch_code"
              disabled
              value={vaspDetail.branch_code || ''}
              onChange={onChange}
            /></>
        }
        {_.get(vaspDetail, 'errors.vaspCode') && (
          <p style={{ margin: '8px', color: 'red', fontSize: '1rem' }}>
            *{_.get(vaspDetail, 'errors.vaspCode')}
          </p>
        )}
      </div>
      <div>
        <TextField
          label="Company ID"
          id="margin-none"
          style={{ margin: 8 }}
          className={classes.textField}
          helperText={getError('company_id')}
          name="company_id"
          value={vaspDetail.company_id || ''}
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-autowidth-label">VASP Category</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            name="vasp_category"
            value={vaspDetail.vasp_category || 'Virtual/Fiat'}
            onChange={onChange}
            fullWidth
            disabled={!isEditableForUpdateVasp}
          >
            <MenuItem value="Virtual/Fiat">Virtual/Fiat</MenuItem>
            <MenuItem value="Virtual/Virtual">Virtual/Virtual</MenuItem>
            <MenuItem value="Transfer">Transfer</MenuItem>
            <MenuItem value="Custodian">Custodian</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <TextField
          label="Estimated number of customers"
          id="margin-none"
          style={{ margin: 8, width: 400 }}
          className={classes.textField}
          helperText={getError('estimated_customer_number')}
          name="estimated_customer_number"
          value={vaspDetail.estimated_customer_number || (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')}
          required
          placeholder="Number"
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
        <TextField
          label="Estimated annual transaction volume (in US$)"
          id="margin-none"
          style={{ margin: 8, width: 400 }}
          className={classes.textField}
          helperText={getError('estimated_annual_transaction_volume')}
          name="estimated_annual_transaction_volume"
          inputProps={{ step: 'any' }}
          value={
            vaspDetail.estimated_annual_transaction_volume ||
            (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')
          }
          required
          placeholder="Number"
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
      </div>
      <TextField
        label="Estimated annual number of Travel Rule data transfers"
        id="margin-none"
        style={{ margin: 8, width: 600 }}
        className={classes.textField}
        helperText={getError('estimated_annual_travel_rule_data_transfer_number')}
        name="estimated_annual_travel_rule_data_transfer_number"
        value={
          vaspDetail.estimated_annual_travel_rule_data_transfer_number ||
          (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')
        }
        required
        fullWidth
        placeholder="Number"
        onChange={onChange}
        disabled={!isEditableForUpdateVasp}
      />
      <div>
        <TextField
          label="Beneficiary VASP API endpoint"
          id="margin-none"
          style={{ margin: 8, width: 500 }}
          className={classes.textField}
          helperText={getError('vasp_receive_url')}
          required
          name="vasp_receive_url"
          value={
            vaspDetail.vasp_receive_url ||
            (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')
          }
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
        <TextField
          label="Beneficiary validate address endpoint URL"
          id="margin-none"
          style={{ margin: 8, width: 500 }}
          className={classes.textField}
          helperText={getError('callback_validate_addr_url')}
          name="callback_validate_addr_url"
          value={
            vaspDetail.callback_validate_addr_url ||
            (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')
          }
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />

        <TextField
          label="Beneficiary receive notification of txid endpoint URL"
          id="margin-none"
          style={{ margin: 8, width: 500 }}
          className={classes.textField}
          helperText={getError('callback_txid_url')}
          required
          name="callback_txid_url"
          value={
            vaspDetail.callback_txid_url ||
            (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')
          }
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />

        <TextField
          label="VASP server health check endpoint URL"
          id="margin-none"
          style={{ margin: 8, width: 500 }}
          className={classes.textField}
          helperText={getError('callback_vasp_server_health_check_url')}
          name="callback_vasp_server_health_check_url"
          value={
            vaspDetail.callback_vasp_server_health_check_url ||
            (isEditableForUpdateVasp ? '' : 'Not applicable since VASP didn’t fill in.')
          }
          placeholder="(URL)"
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
      </div>
      <div>
        <TextField
          label="Sygna VASP server health status"
          id="margin-none"
          style={{ margin: 8, width: 500 }}
          className={classes.capitalizedTextField}
          helperText={getError('vasp_server_status')}
          name="vasp_server_status"
          value={
            vaspDetail.vasp_server_status || 'Unknown'
          }
          disabled
        />
      </div>
      <div>
        <TextField
          label="Fixed static IP Address"
          id="margin-none"
          style={{ margin: 8, width: 500 }}
          className={classes.textField}
          inputProps={{
            pattern:
              '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$',
          }}
          helperText={getError('vasp_fixed_ip')}
          name="vasp_fixed_ip"
          value={vaspDetail.vasp_fixed_ip || ''}
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
      </div>
      <div>
        <TextField
          id="standard-full-width"
          label="VASP Public Key"
          style={{ margin: 8 }}
          placeholder=""
          helperText={getError('pem_pubkey')}
          fullWidth
          required
          margin="normal"
          name="pem_pubkey"
          value={(vaspDetail.pem_pubkey || '').trim()}
          multiline
          onChange={onChange}
          disabled={!isEditableForUpdateVasp}
        />
      </div>
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            id="go_live_dt"
            style={{ margin: 8, marginTop: 20 }}
            variant="inline"
            ampm={false}
            clearable
            label="Go-live Timestamp"
            format="YYYY/MM/DD HH:mm"
            value={vaspDetail.go_live_dt}
            name="go_live_dt"
            onChange={onDateChange}
            disabled={!isEditableForUpdateVasp}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDateTimePicker
            id="account_expiration_dt"
            style={{ margin: 8, marginTop: 20 }}
            variant="inline"
            ampm={false}
            clearable
            label="Account Expiration Date"
            format="YYYY/MM/DD HH:mm"
            value={vaspDetail.account_expiration_dt || null}
            name="account_expiration_dt"
            onChange={onDateChange}
            disabled={!isEditableForUpdateVasp}
          />
        </MuiPickersUtilsProvider>
      </div>
      {vaspDetail.registration_type === VaspRegisterType.Gate ||
        vaspDetail.registration_type === VaspRegisterType.Hub ? (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ padding: 8 }}>
                <FormLabel component="legend" >
                  <strong>
                    Interoperability
                  </strong>
                </FormLabel>
                <FormLabel component="legend" style={{ marginTop: 10 }}>
                  Questionnaire result from registration: {vaspDetail.paidInteroperability}
                </FormLabel>

                <div>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            //onChange={}
                            name="is_gtr_active"
                            color="primary"
                            checked={vaspDetail.is_gtr_active || false}
                            disabled={!isEditableForUpdateVasp}
                          />
                        }
                        label="Global Travel Rule (GTR)"
                      />
                    </FormGroup>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        id="gtr_expire_date"
                        style={{ margin: 8, marginTop: 20 }}
                        variant="inline"
                        ampm={false}
                        clearable
                        label="GTR Expiration Date"
                        format="YYYY/MM/DD HH:mm"
                        value={vaspDetail.gtr_expired_date || null}
                        onChange={onDateChange}
                        name="gtr_expire_date"
                        disabled={!isEditableForUpdateVasp}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="is_code_active"
                            color="primary"
                            checked={vaspDetail.is_code_active || false}
                            disabled={!isEditableForUpdateVasp}
                          />
                        }
                        label="CODE"
                      />
                    </FormGroup>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        id="code_expired_date"
                        style={{ margin: 8, marginTop: 20 }}
                        variant="inline"
                        ampm={false}
                        clearable
                        label="CODE Expiration Date"
                        format="YYYY/MM/DD HH:mm"
                        value={vaspDetail.code_expired_date || null}
                        onChange={onDateChange}
                        name="code_expired_date"
                        disabled={!isEditableForUpdateVasp}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
            </Grid>

          </Grid>
        </div>
      ) : null}
      {renderNotificationReceivers()}
      {renderAssets()}
      <Grid item style={{ display: isEditableForUpdateVasp ? 'inline-flex' : 'none' }}>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            onSubmit={onSubmit}
            disabled={loading}
            variant="outlined"
            className={updateButtonClassname}
          >
            Update
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </Grid>
    </form>
  );
}

VaspForm.defaultProps = {
  onSubmit: () => { },
  onChange: () => { },
  onDateChange: () => { },
  getError: () => { },
  loading: false,
  updateSuccess: false,
};

VaspForm.propTypes = {
  parent: PropTypes.string.isRequired,
  vaspDetail: PropTypes.shape({
    id: PropTypes.string,
    serial_number: PropTypes.number,
    user_id: PropTypes.string,
    signature: PropTypes.string,
    reason: PropTypes.string,
    operation: PropTypes.string,
    vasp_status: PropTypes.string,
    go_live_dt: PropTypes.string,
    account_expiration_dt: PropTypes.string,
    vasp_name: PropTypes.string,
    vasp_code_prefix: PropTypes.string,
    country_code: PropTypes.string,
    city_code: PropTypes.string,
    branch_code: PropTypes.string,
    company_id: PropTypes.string,
    vasp_category: PropTypes.string,
    vasp_receive_url: PropTypes.string,
    vasp_fixed_ip: PropTypes.string,
    pem_pubkey: PropTypes.string,
    vasp_email: PropTypes.string,
    callback_txid_url: PropTypes.string,
    callback_validate_addr_url: PropTypes.string,
    registration_type: PropTypes.number,
    notification_receivers: PropTypes.arrayOf(PropTypes.string),
    callback_vasp_server_health_check_url: PropTypes.string,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        currency_id: PropTypes.string,
        address: PropTypes.string,
        addr_extra_info: PropTypes.arrayOf(PropTypes.object),
      }),
    ),
    estimated_customer_number: PropTypes.number,
    estimated_annual_transaction_volume: PropTypes.number,
    estimated_annual_travel_rule_data_transfer_number: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func,
  getError: PropTypes.func,
  loading: PropTypes.bool,
  updateSuccess: PropTypes.bool,
};

export default VaspForm;
