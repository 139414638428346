import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';

import _ from 'lodash';
import { RoleId, Operations, OperationStatus } from '../../constants';
import { isVaspEditableByRole } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonNormal: {
    width: '12em',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ReviewButtons({
  roleId,
  vaspStatus,
  operation,
  errors,
  approveLoading,
  approveSuccess,
  deleteLoading,
  deleteSuccess,
  onApproveClick,
  onDeleteClick,
}) {
  const classes = useStyles();

  const approveButtonClassname = classNames(classes.buttonNormal, {
    [classes.buttonSuccess]: approveSuccess,
  });
  const deleteButtonClassname = classNames(classes.buttonNormal, {
    [classes.buttonSuccess]: deleteSuccess,
  });

  const renderApproveButton = (marginLeft = '0em') => (
    <div className={classes.wrapper}>
      <Button
        id="approve_button"
        variant="outlined"
        className={approveButtonClassname}
        style={{ width: '12em', marginLeft }}
        disabled={_.get(errors, 'vaspCode') || approveLoading || deleteLoading}
        onClick={(e) => onApproveClick(e)}
      >
        Approve
      </Button>
      {approveLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

  const renderDeleteButton = (marginLeft = '0em') => (
    <div className={classes.wrapper}>
      <Button
        id="delete_button"
        variant="outlined"
        className={deleteButtonClassname}
        style={{ width: '12em', marginLeft }}
        disabled={approveLoading || deleteLoading}
        onClick={(e) => onDeleteClick(e)}
      >
        Delete
      </Button>

      {deleteLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

  if (roleId !== RoleId.ADMIN) {
    return null;
  }

  return (
    <>
      {renderDeleteButton()}
      {renderApproveButton()}
    </>
  );
}
