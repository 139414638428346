import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import * as l from 'lodash/core';
import { getToken, getRoleId, navigate, parseGraphQLErrorMessage } from '../utils/utils';
import AuthenticatedPage from '../components/authenticated-page';
import Navigator from '../components/navigator';
import Layout from '../components/layout';
import VaspForm from '../components/vasp-form';
import { CustomDialog, ErrorDialog } from '../components/common';
import { RoleId, Operations } from '../constants';
import { GET_UPDATE_REQUEST, REVIEW_UPDATE_REQUEST } from '../crud';
import ReviewButtons from '../components/update-request/review-buttons';

export default function ReviewVaspUpdateRequest({ location }) {
  const token = getToken();
  const vaspRoleId = getRoleId(token);
  if (
    (vaspRoleId !== RoleId.ACCOUNT_MANAGER && vaspRoleId !== RoleId.ADMIN) ||
    !location ||
    !location.state
  ) {
    navigate('/');
    return '';
  }

  const id = location.state ? location.state.parameter.id : {};
  const [vaspDetail, setVaspDetail] = useState({});
  const [approveLoading, setApproveLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [informDialogOpen, setInformDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');
  const [operation, setOperation] = useState('');

  const [reviewRequestMutation] = useMutation(REVIEW_UPDATE_REQUEST);

  const showReviewFailed = (e) => {
    setErrorDialogOpen(true);
    setError(parseGraphQLErrorMessage(e));
  };

  useQuery(GET_UPDATE_REQUEST, {
    variables: {
      id,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (l.has(data, 'getVaspUpdateRequest')) {
        const reviewData = data.getVaspUpdateRequest;
        setVaspDetail({
          ...reviewData,
          vasp_fixed_ip: reviewData.vasp_fixed_ip && reviewData.vasp_fixed_ip.join(','),
        });
      } else {
        setVaspDetail({});
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  const showReviewSuccess = () => {
    setInformDialogOpen(true);
  };

  const handleDeleteClick = async () => {
    setOperation(Operations.DELETE);
    setConfirmDialogOpen(true);
  };

  const handleApproveClick = async () => {
    setOperation(Operations.APPROVE);
    setConfirmDialogOpen(true);
  };

  const genConfirmMessage = () => {
    if (!operation) {
      return '';
    }
    if (operation === Operations.APPROVE) {
      return `You want to approve ${vaspDetail.vasp_code} update request ?`;
    }
    if (operation === Operations.DELETE) {
      return `You want to delete ${vaspDetail.vasp_code} update request ?`;
    }
    throw new Error('invalid operation');
  };

  const handleConfirmClick = async () => {
    if (!operation) {
      return;
    }
    if (operation === Operations.APPROVE) {
      return reviewRequestMutation({
        variables: {
          id: vaspDetail.id,
          approve: true,
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      })
        .then(() => {
          setApproveLoading(false);
          setApproveSuccess(true);
          showReviewSuccess();
        })
        .catch((err) => {
          setApproveLoading(false);
          setApproveSuccess(false);
          showReviewFailed(err);
        });
    }

    if (operation === Operations.DELETE) {
      return reviewRequestMutation({
        variables: {
          id: vaspDetail.id,
          approve: false,
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      })
        .then(() => {
          setDeleteLoading(false);
          setDeleteSuccess(true);
          showReviewSuccess();
        })
        .catch((err) => {
          setDeleteSuccess(false);
          setDeleteLoading(false);
          showReviewFailed(err);
        });
    }
    throw new Error('invalid operation');
  };

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'VASP Update Request List', 'Review Update Request']}
          breadcrumbLinks={['/', '/vasp-update-request-list', '/review-vasp-update-request']}
        />
        <br />
        <div>
          <VaspForm parent="approve-vasp" vaspDetail={vaspDetail} />
        </div>
        <div
          style={{
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ReviewButtons
            roleId={vaspRoleId}
            vaspStatus={vaspDetail.vasp_status}
            operation={vaspDetail.operation}
            errors={vaspDetail.errors}
            approveLoading={approveLoading}
            approveSuccess={approveSuccess}
            deleteLoading={deleteLoading}
            deleteSuccess={deleteSuccess}
            onApproveClick={handleApproveClick}
            onDeleteClick={handleDeleteClick}
          />
        </div>

        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
        <CustomDialog
          open={{ get: confirmDialogOpen, set: setConfirmDialogOpen }}
          title="Confirm"
          message={genConfirmMessage()}
          positiveButtonText="ok"
          onPositiveButtonClick={handleConfirmClick}
          negativeButtonText="cancel"
          onClose={() => {
            setConfirmDialogOpen(false);
          }}
        />
        <CustomDialog
          open={{ get: informDialogOpen, set: setInformDialogOpen }}
          title="Inform"
          message={`${operation} success`}
          positiveButtonText="ok"
          onClose={() => {
            setInformDialogOpen(false);
            setApproveSuccess(false);
            setDeleteSuccess(false);
            navigate('/vasp-update-request-list');
          }}
          timeout={5000}
        />
      </AuthenticatedPage>
    </Layout>
  );
}

ReviewVaspUpdateRequest.defaultProps = {
  location: undefined,
};

ReviewVaspUpdateRequest.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      parameter: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
};
